import React, { useState, useContext, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import { AppContextProvider, AppContext } from './context/AppContext';
import { View, StyleSheet, Text } from 'react-native';
import { BackgroundImageProvider } from './components/BackgroundImageContext';

const darkTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#BB86FC',
    accent: '#03DAC6',
    background: '#121212',
    surface: '#121212',
    text: '#FFFFFF',
  },
};

// Lazy imports
const Home = lazy(() => import('./components/Home'));
const Login = lazy(() => import('./components/Login'));
const AccountManagement = lazy(() => import('./components/AccountManagement'));
const AccountCreation = lazy(() => import('./components/AccountCreation'));
const PasswordReset = lazy(() => import('./components/PasswordReset'));
const ChatScreen = lazy(() => import('./components/ChatScreen'));
const FAQ = lazy(() => import('./components/FAQ'));
const Contact = lazy(() => import('./components/Contact'));
const AgeVerification = lazy(() => import('./components/AgeVerification'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const CharacterVoting = lazy(() => import('./components/CharacterVoting'));

function AppContent() {
  const { user } = useContext(AppContext);
  const [isAgeVerified, setIsAgeVerified] = useState(() => {
    return localStorage.getItem('isAgeVerified') === 'true';
  });

  useEffect(() => {
    localStorage.setItem('isAgeVerified', isAgeVerified);
  }, [isAgeVerified]);

  return (
    <Suspense fallback={<View style={styles.loadingContainer}><Text>Loading...</Text></View>}>
      <Routes>
        {!isAgeVerified ? (
          <Route path="*" element={<AgeVerification onVerified={() => setIsAgeVerified(true)} />} />
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/account" element={<AccountManagement />} />
            <Route path="/create-account" element={<AccountCreation />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/chat/:characterId/:sceneIndex" element={<ChatScreen />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/vote" element={<CharacterVoting />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

function App() {
  return (
    <Router>
      <View style={styles.container}>
        <BackgroundImageProvider>
          <AppContextProvider>
            <PaperProvider theme={darkTheme}>
              <AppContent />
            </PaperProvider>
          </AppContextProvider>
        </BackgroundImageProvider>
      </View>
    </Router>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#121212',
  },
});

export default App;